
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { FC, useEffect, useState } from 'react'

export interface TableInputInterface {
    params: {
        field: string,
        value: string | number;
        row: {[key:string]: number |boolean | string}
    }
}

export const TableInput: FC<TableInputInterface> = ({
    params,
}) => {
    const { setRecords, setTableErrors } = useServiceRunsContext();
    const [touched, setTouched] = useState(false);
    const [erorred, setErorred] = useState(false);
    const [localValue, setLocalValue] = useState(params.value);

    useEffect(() => {
        if (touched) {
            if (typeof +localValue === "number" && !isNaN(+localValue) && localValue !== "") {
                setTableErrors(prev => prev.filter(item => !(item.fieldName === params.field && item.rowID === params.row.id)))
                setErorred(false);
            } else {
                setTableErrors(prev => {
                    const filterCurrentRecord = prev.filter(item => !(item.fieldName === params.value && item.rowID === params.row.id));
                    filterCurrentRecord.push({ fieldName: params.field, rowID: +params.row.id });
                    return filterCurrentRecord;
                })
                setErorred(true);
            }
        }
    }, [localValue, touched])

    useEffect(() => {
        if (params.value !== localValue) {
            setLocalValue(params.value);
        }
    }, [params.value])

    return (
        <>
            <input
                type={"text"}
                className={`form-control ${touched ? erorred ? "is-invalid" : "is-valid" : ""}`}
                onChange={(e) => {
                    setLocalValue(e.target.value);
                    // setRecords(prev => prev.map(record =>
                    //     +record.id === params.row.id ? { ...record, [params.field]: +e.target.value } : record));
                }}
                onBlur={(e) => {
                    setTouched(true);
                    if (+e.target.value % 1 !== 0) { // check if the value has decimal part and take only first 6 dec num
                        setLocalValue(parseFloat((+e.target.value).toFixed(6))); 
                        setRecords(prev => prev.map(record =>
                            +record.id === params.row.id ? { ...record, [params.field]: parseFloat((+e.target.value).toFixed(6)) } : record));
                    } else {
                        setLocalValue(Math.trunc(+e.target.value)); // if there are only zeroes after decimal numbers we remove them
                        setRecords(prev => prev.map(record =>
                            +record.id === params.row.id ? { ...record, [params.field]: Math.trunc(+e.target.value) } : record));
                    }
                }}
                value={localValue}
                autoComplete="off"
                onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                        ev.preventDefault();
                    }
                }}
            />
        </>
    );
}
