import { FC } from 'react';
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context';
import { useLocation, useNavigate } from 'react-router-dom';

export interface AddButtonInterface {
    form: {
        values: { jsonID: { [key: string]: { [key: string]: string } | { [key: string]: string }[] }, serviceID: string },
        dirty: boolean,
        errors: {}
    },
    state: {},
    label: string,
    isLoading: boolean,
    showInEdit: boolean,
    showInNew: boolean,
    color: string,
    disabled: boolean,
    addCompositeFields: { destFromDesc: string, destFromValue: string, source: string }[],
    addDefaultFields: { name: string, value: string }[],
}


export const AddButton: FC<AddButtonInterface> = ({
    label,
    isLoading = false,
    form: { dirty, values, errors },
    showInNew,
    color,
    disabled,
    addCompositeFields,
    addDefaultFields
}) => {

    const { records,
        setRecords,
        newRow,
        tableContentReady,
        setEditTable,
        setNewRow,
        setTableContentReady
    } = useServiceRunsContext();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const onClick = () => {
        // create new row in the table from the new page
        const localObject: { [key: string]: string | number } = {};
        if (addCompositeFields) {
            addCompositeFields.forEach(field => {
                const currentSourceObject = (values.jsonID[field.source] as { [key: string]: string })
                localObject[field.destFromValue] = currentSourceObject.value;
                localObject[field.destFromDesc] = currentSourceObject.name.replace(` - ${currentSourceObject.value}`, "");
            });

        }
        if (addDefaultFields) {
            addDefaultFields.forEach(field => {
                localObject[field.name] = field.value;
            });
        }
        const ids = records.map(rec => +rec.id);
        localObject.id = ids.length > 0 ? +Math.max(...ids) + 1 : 1;
        localObject.operation = "add";

        setRecords(prev => [...prev, localObject]);
 
        const filtredState = { ...values }
        // delete from formik values the fields from new page
        if (addCompositeFields) {
            addCompositeFields.map(field => field.source).forEach(field => delete filtredState.jsonID[field])
        }

        // navigate to the main page again by preserving in the state 
        // the chosen values from the main page
        setEditTable(false);
        setNewRow(false);
        setTableContentReady(false);
        navigate(currentPath, {
            state: {
                "service_descriptive_name": filtredState.serviceID,
                "json_payload": filtredState.jsonID
            }
        });
    }

    return (<>

        {(showInNew && newRow && tableContentReady) &&
            <>

                <div className="input-group">
                    <button
                        type='button'
                        className={`btn btn-color-${color ? color : "success"} btn-${color ? color : "success"} text-white btn-sm me-5`}
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => onClick()}
                        disabled={disabled || !dirty || Object.keys(errors).length > 0}
                    >
                        <div className="d-flex align-items-center">
                            <span>{label}</span>
                            {isLoading && (
                                <span className="spinner-border spinner-border-sm text-light ms-2"></span>
                            )}
                        </div>
                    </button>
                </div>
            </>}
    </>
    );
}