import { FC } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { useLocation, useNavigate } from 'react-router-dom';

export interface BackButtonInterface {
  form: {
    values: { jsonID: { [key: string]: { [key: string]: string } | { [key: string]: string }[] }, serviceID: string }
  },
  label: string,
  keepState: boolean,
  removeFromState: string
}


export const BackButton: FC<BackButtonInterface> = ({
  form: { values },
  label,
  keepState,
  removeFromState
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { setEditTable, setNewRow, setTableContentReady, tableContentReady } = useServiceRunsContext();
  
  const handleSubmit = () => {
    setEditTable(false);
    setNewRow(false);
    if (keepState) {
      const filtredState = { ...values }
      if (removeFromState) {
        removeFromState.split(',').forEach(field => delete filtredState.jsonID[field])
      }
      navigate(currentPath, {
        state: {
          "keep_pagination": true,
          "service_descriptive_name": filtredState.serviceID,
          "json_payload": filtredState.jsonID
        }
      });
    } else {
      navigate(currentPath, {
        state: {
          "keep_pagination": true,
          "service_descriptive_name": values.serviceID,
          "json_payload": {bu: values.jsonID?.bu, module_id: values.jsonID?.module_id}
        }});
    }

    setTableContentReady(false);
  }

  return (
    <>
      {tableContentReady &&
        <button
          type='button'
          className='btn btn-color-success btn-danger text-white btn-sm me-5'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => handleSubmit()}
        >
          <div className="d-flex align-items-center">
            <span>{label}</span>
          </div>
        </button>
      }
    </>
  );
}