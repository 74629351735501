import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FC, useEffect, useState } from 'react';
import { getIn } from "formik";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const requiredFieldStyle = (req: boolean) => {
  return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

export interface DatePickerViewsInterface {
  field: {
    name: string,
    value: Date
  },
  form: {
    touched: {},
    errors: {},
    setFieldValue: (a: string, b: null | Date | string | number | Array<string | number | { [key: string]: string | Date }>) => {},
    values: { serviceID: string, jsonID: { [key: string]: string | { [key: string]: string | Date }[] } },
  },
  disabled: boolean,
  novalidation: boolean,
  params: { row: { [key: string]: string }, field: string, value: string },
  label: string,
  required: boolean,
  dateFormat: string
}

export const DatePickerViews: FC<DatePickerViewsInterface> = ({
  field,
  form: { touched, errors, setFieldValue, values },
  disabled,
  params,
  label,
  required,
  dateFormat
}) => {
  const errorMessage = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const [date, setDate] = useState<Date | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {

    if (values.jsonID[field.name.replace('jsonID.', '')]) {
      const currentMC = transformToDate(values.jsonID[field.name.replace('jsonID.', '')] as string)
      setDate(currentMC!);
    }

  }, [])

  const transformToDate = (monthCode: string) => {
    if (dateFormat === "YYYYmm") {
      return new Date(+monthCode.substring(0, 4), +monthCode.substring(4, 6) - 1, 1, 0, 0, 0, 0);
    }
    if (dateFormat === "YYYYmmdd") {
      return new Date(+monthCode.substring(0, 4), +monthCode.substring(4, 6) - 1, +monthCode.substring(6, 8), 0, 0, 0, 0);
    }
  }


  const handleAccept = (newValue: Date | null) => {
    
    setFieldValue(field.name, newValue as Date)
    setDate(newValue as Date)
    if (dateFormat === "YYYYmm") {
      if (newValue) {
        setFieldValue(field.name, `${newValue.getFullYear()}${(newValue.getMonth() + 1).toString().padStart(2, "0")}`)
      }
    }
    if (dateFormat === "YYYYmmdd") {
      if (newValue) {
        setFieldValue(field.name, `${newValue.getFullYear()}${(newValue.getMonth() + 1).toString().padStart(2, "0")}${newValue.getDate().toString().padStart(2, "0")}`)
      }
    }
  }

  const handleChange = (newValue: Date | null) => {
    if(!isPopupOpen) handleAccept(newValue)
  }

  return (
    <>
      {label && <label className="fw-bolder font-size-h4 label-custom mb-2">{label}{requiredFieldStyle(required)}</label>}
      <div className="input-group ">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={dateFormat === "YYYYmm" ? ['month', 'year'] : undefined}
            onChange={handleChange}
            onOpen={() => setIsPopupOpen(true)}
            onClose={() => setIsPopupOpen(false)}
            onAccept={handleAccept}
            disabled={disabled}
            value={date}
            sx={{
              '&.MuiTextField-root': {
                flex: '1 1 0'
              },
              '& .MuiInputBase-input': {
                padding: "1rem 0.5rem 1rem 1rem",
                '&.MuiOutlinedInput-input': {
                  '&.Mui-disabled': {
                    'WebkitTextFillColor': '#181C32'
                  }
                }
              },
              '& .MuiInputBase-root': {
                '& .Mui-disabled': {
                  backgroundColor: '#eff2f5',
                },
                '&.MuiOutlinedInput-root': {
                  '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E4E6EF',
                    }
                  },
                  '&.Mui-focused': {

                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#B5B5C3',
                      borderWidth: '1px'
                      // backgroundColor: 'blue'
                    }
                  }
                },
                '&.MuiOutlinedInput-root:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E4E6EF',
                    borderWidth: '1px'
                    // backgroundColor: 'blue'
                  }
                }
              },

              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #e4e6ef',
                borderRadius: '0.475rem',
                '&.Mui-focused': {
                  color: '#474761'
                }
              }
            }}
          />
        </LocalizationProvider>
      </div>
      {
        !params && touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
      }
    </>
  );
}