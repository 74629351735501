import { FC } from 'react';
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context';

export interface RowButtonInterface {
    form: {
        values: { jsonID: { closeMonth: string, newDates: Array<{ shipFrom: string | number, month: string, datetimeClose: string }>, day: string } },
        dirty: boolean,
        setFieldValue: (a: string, b: Date | string | number | Array<string | number | { [key: string]: string | Date | number}>) => {},
    },
    state: {},
    label: string,
    isLoading: boolean,
    showInEdit: boolean,
    showInNew: boolean,
    action: string,
    color: string,
    disabled: boolean
}


export const RowButton: FC<RowButtonInterface> = ({
    label,
    isLoading = false,
    form: { dirty, values, setFieldValue },
    action,
    color,
    disabled
}) => {
    const { records, setRecords } = useServiceRunsContext();
    const { commonQueries } = useServiceRunsContext();
    const onClick = () => {
        // get the Close Month Label from query Months corresponding to selected value
        const closeMonthLabel = commonQueries?.Months.find(item => item.value === values.jsonID.closeMonth)?.currentkey
        // get the current value for newDates key
        let newDates = [...values.jsonID?.newDates];
        // here we rotate the table rows change the table values and accumulate the new value of newDates array
        setRecords(prev => prev.map(row => {
            //add new object to newDates array for each table row, but first filtered out the old values if exist
            const newDate = { datetimeClose: values.jsonID.day, shipFrom: row.sec_cd, month: closeMonthLabel! }
            const filteredValues = newDates.filter(item => !(item.shipFrom === row.sec_cd && item.month === closeMonthLabel))
            newDates = [...filteredValues, newDate]
            // change the current table row
            const newRow = { ...row, [closeMonthLabel!]: values.jsonID.day };
            return newRow;
        }))
        if(newDates) setFieldValue("jsonID.newDates", newDates)
    }

    return (
        <>
            {label && <label style={{ visibility: 'hidden' }} className="fw-bolder font-size-h4 label-custom mb-2">{label}</label>}
            <div className="input-group">
                <button
                    type='button'
                    className={`btn btn-color-${color ? color : "success"} btn-${color ? color : "success"} text-white btn-sm me-5`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    style={{ paddingBlock: '0.75rem', paddingInline: '4rem', fontSize: '1.3rem' }}
                    onClick={() => onClick()}
                    disabled={disabled || !records || records?.length === 0}
                >
                    <div className="d-flex align-items-center">
                        <span>{label}</span>
                        {isLoading && (
                            <span className="spinner-border spinner-border-sm text-light ms-2"></span>
                        )}
                    </div>
                </button>
            </div>
        </>
    );
}