import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import { FC, useEffect } from 'react'

const getFieldCSSClasses = (touched: {}, errors: {}, novalidation: boolean) => {
    const classes = ["form-control select-drop-down",];
    if (!novalidation) {
        if (touched && errors) {
            classes.push("is-invalid");
        }

        if (touched && !errors) {
            classes.push("is-valid");
        }
    }


    return classes.join(" ");
};

const requiredFieldStyle = (req: boolean) => {
    return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

export interface selectInterface {
    field: {
        name: string,
        value: string | number | undefined | number
    },
    form: {
        touched: {},
        errors: {},
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: string | number | Array<string | number>) => {},
        values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
    },
    label: string,
    required: boolean,
    isLoading: boolean,
    disabled: boolean,
    help?: getFieldHelpInterface,
    type: string,
    multipleChoise: boolean,
    hidden: boolean,
    options: Array<{ label?: string, value?: string | number, name?: string, email?: string }>,
    defaultOption: string,
    editmode: boolean,
    defaultValueSelected: string,
    novalidation: boolean,
    selectFirst: boolean
}


export const Select: FC<selectInterface> = ({
    field,
    form: { touched, errors, setFieldTouched, setFieldValue, values },
    label,
    help,
    required = true,
    isLoading,
    disabled,
    type = "text",
    multipleChoise = false,
    hidden = false,
    options,
    defaultOption = '',
    editmode,
    defaultValueSelected,
    novalidation,
    selectFirst
}) => {

    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    useEffect(() => {

        if (defaultValueSelected && values.jsonID[field.name.replace("jsonID.", "")] === "default") {
            setFieldValue(field.name, defaultValueSelected);
            setFieldTouched(field.name, true);
        }

    }, [defaultValueSelected, values.jsonID[field.name.replace("jsonID.", "")]])

    useEffect(() => {

        if (options?.length > 1 && selectFirst) {
            setFieldValue(field.name, options[1].value as string);
            setFieldTouched(field.name, true);
        }

    }, [JSON.stringify(options)])

    return (

        <>
            {label && <label hidden={hidden} className="fw-bolder font-size-h4 label-custom mb-2">{label}{requiredFieldStyle(required)}</label>}
            <div className="input-group">
                {
                    <select
                        hidden={hidden}
                        // style={{ color: (field.value === 'default' || field.value === "init;init") ? "#BFBBD3" : "inherit" }}
                        className={getFieldCSSClasses(touch, errorMessage, novalidation)}
                        {...field}
                        value={field.value}
                        disabled={disabled || (options?.length === 0 || !options)}
                        multiple={multipleChoise}
                        onChange={(e) => {
                            setFieldTouched(field.name, false);
                            if (multipleChoise) {
                                let k: Array<string | number> = [];
                                if (Array.isArray(e.target.selectedOptions)) e.target.selectedOptions.forEach(i => k.push(i.value));
                                setFieldValue(field.name, k);
                            } else {
                                setFieldValue(field.name, e.target.value);
                            }
                        }}
                    >
                        {
                            options?.length > 0 ?
                                options?.map((opt) => {
                                    return opt.value === 'default'
                                        ? <option value={opt.value} key={field.name + opt.value} disabled> {defaultOption || opt.name} </option>
                                        : <option value={opt.value} key={field.name + opt.value}> {opt.name} </option>
                                }) :
                                <option value={'default'} key={"loading..."} disabled> Loading... </option>
                        }
                    </select>}
                <PopoverHelper help={help} />
                {
                    touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
                }
            </div>
        </>
    );
}
