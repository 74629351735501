import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { TableComponent } from "../../../app/components/table/NewTable";
import { useMemo } from "react";
import { FC } from 'react'
import { useLazyConnectDBQuery, useLazyConnectFunctionQuery } from "../../../app/modules/ServiceRuns/redux/cloudAppAPI";
import { useCreateColumnsObject, useGetColumnInitialState, useResetTableContentOnBUServiceChange } from '../../../app/modules/ServiceRuns/hooks/TableHooks'
import { useCheckQueryReady, useSetPreQuery } from '../../../app/modules/ServiceRuns/hooks/RoleTableHooks'
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { useSetTableNewFieldsDefaults } from "../../../app/modules/ServiceRuns/hooks/DynamicServiceHooks";
import { FieldConfigInterface } from "../../../app/modules/ServiceRuns/interfaces/serviceRunsInterfaces";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

export interface TableFieldInterface {
  field: {
    name: string,
    value: string | number | undefined | number
  },
  form: {
    touched: {},
    errors: { jsonID: { [key: string]: string[] } },
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: string | number | Array<string | number> | { [key: string]: string | number }[]) => {},
    values: { serviceID: string, jsonID: { [key: string]: string } },
    initialValues: { jsonID: { [key: string]: string } }
  },
  data_fields: FieldConfigInterface[],
  queryparams: string,
  service_descriptive_name: string,
  newRecordLabel: string,
  hideToolBar: boolean,
  disableTableEdit: boolean,
  checkboxSelection: boolean,
  checkboxSelectionVisibleOnly: boolean,
  funcQueryName: string,
  dynamicHeaderExceptions: string[],
  filterBy: string[],
  disableColumnMenu?: boolean,
  disablePagination?: boolean,
  minDateRestriction: {
    property: string,
    query: string,
    depProp: string
  },
  maxDateRestriction: string,
  identityparams: string,
  preQuery: boolean,
  // keepRecords: boolean,
  dynamicHeaders: boolean,
  showCalendarIfFirstPartNumber: boolean,
  serverSideFiltering: boolean
}

export const TableField: FC<TableFieldInterface> = ({
  field,
  form,
  data_fields,
  queryparams,
  service_descriptive_name,
  newRecordLabel,
  hideToolBar,
  disableTableEdit,
  checkboxSelection,
  checkboxSelectionVisibleOnly,
  funcQueryName,
  dynamicHeaderExceptions,
  filterBy,
  disableColumnMenu = false,
  disablePagination = false,
  minDateRestriction,
  maxDateRestriction,
  identityparams,
  preQuery,
  // keepRecords,
  dynamicHeaders,
  showCalendarIfFirstPartNumber,
  serverSideFiltering
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  let { serviceid } = useParams();
  const [trigger, { data, isLoading, isFetching }] = useLazyConnectDBQuery();
  const [funcTrigger, { data: funcData, isLoading: funcIsLoading, isFetching: funcIsFetching }] = useLazyConnectFunctionQuery();
  const [filterModelChanged, setFilterModelChanged] = useState(false);
  const { paginationModel,
    setPaginationModel,
    setSortModelData,
    sortModelData,
    setEditTable,
    editTable,
    setNewRow,
    filterModel,
    setFilterModel,
    columns,
    records,
    setRecords,
    // currentServiceConfig,
    setInitialRecords,
    tableValuesObject,
    setTableQueryValuesObject,
    getTableData,
    setGetTableData,
    tableFieldName,
    setTableFieldName
  } = useServiceRunsContext();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [filterObject, setFilterObject] = useState<{ [key: string]: string }>({});
  useResetTableContentOnBUServiceChange();
  const defaultNewValues = useSetTableNewFieldsDefaults(data_fields);
  useCreateColumnsObject(
    data_fields,
    form,
    dynamicHeaders,
    showCalendarIfFirstPartNumber,
    data,
    identityparams,
    dynamicHeaderExceptions,
    minDateRestriction,
    maxDateRestriction,
    checkboxSelectionVisibleOnly
  );
  useSetPreQuery(form.setFieldValue, data && "tabledata" in data ? data.user_exist : false, preQuery)
  const initialState = useGetColumnInitialState(data_fields);
  const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);

  useEffect(() => {

    if (checkboxSelection) form.setFieldValue('jsonID.rowSelection', records.filter(r => rowSelectionModel.includes(r.id)))
  }, [rowSelectionModel])

  useEffect(() => {
    const state = location.state as { [key: string]: string }
    
    if (disablePagination) {
      setPaginationModel({
        page: 0,
        pageSize: 9999999
      });
    } else {
      if ((state && "keep_pagination" in state) === null) {
        setPaginationModel({
          page: 0,
          pageSize: 10
        });
      }

    }

  }, [])

  // if we change to another service with a table or another table field we trigger get data process via setTableQueryValuesObject({})
  useEffect(() => {
    if (tableFieldName == "" || tableFieldName.service !== serviceid! || tableFieldName.name !== field.name) {
      setRecords([]);
      setTableFieldName({ service: serviceid!, name: field.name });
      setTableQueryValuesObject({});
    }

  }, [field.name, serviceid])

  // useEffect(() => {

  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [form.values.serviceID, columns])


  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [data_fields[0].id])
  // console.log(getTableData,"getTableData", serviceid, service_descriptive_name, form.values.serviceID)

  useEffect(() => {
    // set !editTable in order to escape running when click on row. It cause problems in useCheckQueryReady
    // if (
    //   queryReady &&
    //   !editTable &&
    //   service_descriptive_name === form.values.serviceID &&
    //   (
    //     records.length === 0 ||
    //     (
    //       triggerServerSideFiltering &&
    //       filterModel.items.length > 0
    //     )
    //   )
    // ) {
    //   console.log(filterModel)

    if (queryReady &&
      !editTable &&
      serviceid === service_descriptive_name &&
      // service_descriptive_name === form.values.serviceID &&
      getTableData
      // records.length === 0 &&
      // JSON.stringify(tableValuesObject) !== JSON.stringify(queryValuesObject)
    ) {
      // console.log(field.name.replace('jsonID.', ''),currentServiceConfig,serviceid, form.values.serviceID, service_descriptive_name)
      if (funcQueryName) {
        funcTrigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config", filterParams: filterModel });
        setTableQueryValuesObject(queryValuesObject);
      } else {
        trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" });
        setTableQueryValuesObject(queryValuesObject);
      }
    }

  // }, [JSON.stringify(queryValuesObject), service_descriptive_name, JSON.stringify(records), triggerServerSideFiltering])
    setGetTableData(false);
  }, [getTableData])

  useEffect(() => {

    if (filterBy) {
      let localFilteredObject: { [key: string]: string } = {};
      filterBy.forEach(filter => localFilteredObject[filter] = form.values.jsonID[filter])
      if (JSON.stringify(localFilteredObject) !== JSON.stringify(filterObject)) {
        setFilterObject(localFilteredObject)
      }
    }


  }, [form.values])

  useEffect(() => {

    if (data?.tabledata) {
      if (filterBy) {
        if (filterBy.every(filter => !["", "default", undefined].includes(form.values.jsonID[filter]))) {
          const recordsData = data!.tabledata.filter(entry => filterBy.every(filter => entry[filter] === form.values.jsonID[filter]));
          setRecords(recordsData);
          setInitialRecords(recordsData);
        }
      } else {
        setRecords(data!.tabledata);
        setInitialRecords(data!.tabledata);
      }
    }

  }, [filterObject, data])

  useEffect(() => {

    if (funcData) {
      if (filterBy) {
        if (filterBy.every(filter => !["", "default", undefined].includes(form.values.jsonID[filter]))) {
          const recordsData = funcData!.filter(entry => filterBy.every(filter => entry[filter] === form.values.jsonID[filter]));
          setRecords(recordsData);
          setInitialRecords(recordsData);
        }
      } else {
        setRecords(funcData!);
        setInitialRecords(funcData!);
      }
    }
  }, [filterObject, funcData])

  // useEffect(() => {
  //   if (keepRecords) {
  //     if (currentServiceConfig.service_descriptive_name !== form.values.serviceID) {

  //       setRecords([]);
  //       setInitialRecords([]);
  //     }
  //   } else {
  //     if (JSON.stringify(tableValuesObject) !== JSON.stringify(queryValuesObject) && Object.keys(queryValuesObject).length > 0) {
  //       console.log("clear",tableValuesObject, queryValuesObject)
  //       setRecords([]);
  //       setInitialRecords([]);
  //     }

  //   }
  // }, [form.values.serviceID, JSON.stringify(queryValuesObject)])

  useEffect(() => {
    // console.log(Object.keys(queryValuesObject).length > 0 ,
    // JSON.stringify(tableValuesObject), JSON.stringify(queryValuesObject) ,
    // queryReady)
    if (Object.keys(queryValuesObject).length > 0 &&
      JSON.stringify(tableValuesObject) !== JSON.stringify(queryValuesObject) &&
      queryReady) {

      setGetTableData(true);
      setRecords([]);
      setInitialRecords([]);
    }
  }, [JSON.stringify(tableValuesObject), JSON.stringify(queryValuesObject), queryReady, service_descriptive_name])

  const handleRowClick = useCallback((params: { row: { can_click: boolean; service_descriptive_name: string; } }) => {
    if (params.row.can_click) {
      setEditTable(true)
      console.log(params.row);
      navigate(`/services/createservicerun/${form.values.serviceID}`, {
        state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...params.row } }
      });
    }
  }, [form.values.serviceID])

  const newRecord = () => {
    setNewRow(true)
    navigate(`/services/createservicerun/${form.values.serviceID}`, {
      state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...defaultNewValues } }
    });
  }

  return (
    <>
      <TableComponent
        columns={columns}
        filteredData={useMemo(() => (records), [records])}
        loading={isFetching || isLoading || funcIsLoading || funcIsFetching}
        handleRowClick={handleRowClick}
        newRecord={newRecord}
        paginationModel={useMemo(() => paginationModel, [paginationModel])}
        onPaginationModelChange={setPaginationModel}
        sortModelData={useMemo(() => sortModelData, [sortModelData])}
        onSortModelDataChange={setSortModelData}
        newRecordLabel={newRecordLabel}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        initialState={initialState}
        hideToolBar={hideToolBar}
        disableTableEdit={disableTableEdit}
        checkboxSelection={checkboxSelection}
        checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
        disableColumnMenu={disableColumnMenu}
        disablePagination={disablePagination}
        getTableData={getTableData}
        setGetTableData={setGetTableData}
        serverSideFiltering={serverSideFiltering}
        filterModelChanged={filterModelChanged}
        setFilterModelChanged={setFilterModelChanged}
      />
    </>
  )
}
