import React, { FC } from "react";
import { useEffect, useState } from "react";
import { FieldConfigInterface } from '../interfaces/serviceRunsInterfaces'
import { useServiceRunsContext } from "../context/ServiceRuns-context";
import { DGDatePicker } from "../../../../_metronic/partials/forms/DGDatePicker";
import { TableInput, TableInputInterface } from "../../../../_metronic/partials/forms/TableInput";
import { DeleteButton, DeleteButtonInterface } from "../../../../_metronic/partials/forms/DeleteButton"
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Field } from "formik";
import { HistoryButton, HistoryButtonInterface } from "../../../../_metronic/partials";
import { RoleCheckbox, RoleCheckboxInterface } from "../components/CreateServiceRun/RoleTable/RoleCheckbox";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useGlobalContext } from "../../../components/GlobalContext/GlobalContext";

// interface inputMapInterface {
//     [key: string]:
//     FC<HistoryButtonInterface> |
//     FC<inputInterface>
// }

// const inputMap: inputMapInterface = {
//     HistoryButton: HistoryButton,
//     Input: Input,
// }

// interface ButtonConfInterface {
//     label: string;
//     id: string;
//     type: keyof typeof inputMap;
//     queryparams: string;
//     state: any;
//     nm_ptrn: any;
//     showInEdit: boolean;
//     showInNew: boolean;
//     tableFieldType: string;
//     tableValues: { id: string, label: string, monitored: boolean }[];
//     modalTopic: string;
//     action: string;
//     color: string;
// }

// const DynamicTableService: FC<{
//     fieldconf: FieldConfigInterface,
//     params: any,
//     form: any
// }> = ({
//     fieldconf,
//     params,
//     form
// }) => {
//         const { serviceid } = useParams();

//         return (
//             <>
//                 <Formik
//                     enableReinitialize={true}
//                     initialValues={{ serviceID: serviceid as string, jsonID: { ...form.values.jsonID, ...params.row } }}
//                     onSubmit={() => { }}
//                 >
//                     {formik => {

//                         return (
//                             <Form className="form form-label-right">
//                                 <>
//                                     <div className="d-flex">
//                                         <div key={fieldconf.id}>
//                                             <Field
//                                                 label={fieldconf.label}
//                                                 id={fieldconf.id}
//                                                 name={`jsonID.${fieldconf.id}`}
//                                                 component={inputMap[fieldconf.type]}
//                                                 queryparams={fieldconf.queryparams}
//                                                 nm_ptrn={fieldconf.nm_ptrn}
//                                                 showInEdit={fieldconf.showInEdit}
//                                                 showInNew={fieldconf.showInNew}
//                                                 tableFieldType={fieldconf.tableFieldType}
//                                                 tableValues={fieldconf.tableValues}
//                                                 modalTopic={fieldconf.modalTopic}
//                                                 action={fieldconf.action}
//                                                 color={fieldconf.color}
//                                                 noLabel={fieldconf.noLabel}
//                                             />
//                                         </div>
//                                     </div>
//                                 </>
//                             </Form>
//                         )
//                     }}
//                 </Formik >
//             </>
//         )
//     }

const renderCellMapper = (
    dl: FieldConfigInterface,
    params: {
        value: string | number | string[] | number[],
        row: { [key: string]: number | boolean | string }
    },
    form: any,
    identityparams: string | undefined,
    data: any
) => {

    interface inputMapInterface {
        [key: string]:
        FC<TableInputInterface> |
        FC<DeleteButtonInterface> |
        FC<RoleCheckboxInterface> |
        FC<HistoryButtonInterface>
    }

    const inputMap: inputMapInterface = {
        deleteButton: DeleteButton,
        tableInput: TableInput,
        module_access: RoleCheckbox,
        email_notification: RoleCheckbox,
        HistoryButton: HistoryButton
    }

    switch (dl.tableFieldType) {
        case 'mdm_updt_flag':
            if (params.value == 'Y') {
                return <div className="d-flex flex-row align-items-center justify-content-center h-100">
                    <AcUnitIcon fontSize='medium' sx={{ color: '#6ac9ce' }} />
                </div>;
            } else {
                return '';
            }
        case 'arrayValue':
            if (Array.isArray(params.value)) {
                return (
                    <Tooltip title={<span style={{ fontSize: '11px', fontWeight: '600' }}>{params.value.join(' | ')}</span>}>
                        <span>
                            {params.value.map((item, index) => (
                                <React.Fragment key={index}>
                                    <span>{index > 0 && ' | '}{item}</span>
                                </React.Fragment>
                            ))}
                        </span>
                    </Tooltip>
                );
            } else {
                return '';
            }
        // case 'component':
        //     return (
        //         <DynamicTableService fieldconf={dl as any} params={params} form={form} />
        //     )
        // case 'module_access':
        //     return <RoleCheckbox
        //         form={form}
        //         rowParams={params}
        //         columnParams={null}
        //         checkboxPropName={dl.id}
        //         identityparams={identityparams || ''}
        //         data={data}
        //     />
        // case 'email_notification':
        //     return <RoleCheckbox
        //         form={form}
        //         rowParams={params}
        //         columnParams={null}
        //         checkboxPropName={dl.id}
        //         identityparams={identityparams || ''}
        //         data={data}
        //     />

        case 'component':
            const CurrentComponent = inputMap[dl.type];
            return <CurrentComponent
                form={form}
                columnParams={null}
                id={dl.id}
                label={dl.label}
                queryparams={dl.queryparams}
                tableFieldType={dl.tableFieldType}
                tableValues={dl.tableValues}
                modalTopic={dl.modalTopic}
                upside={dl.upside}
                identityparams={identityparams || ''}
                data={data}
                copyFieldsFromFormik={dl.copyFieldsFromFormik}
                params={params as {
                    value: string | number;
                    field: string,
                    row: { [key: string]: number | boolean | string }
                    //  {
                    //     id: number,
                    //     can_click: boolean;
                    //     service_descriptive_name: string;
                    //     operation: string,
                    // };
                }}
            />
        // case 'deleteButton':
        //     return <DeleteButton
        //         params={params as {
        //             value: string | number;
        //             field: string,
        //             row: {
        //                 id: number,
        //                 can_click: boolean;
        //                 service_descriptive_name: string;
        //                 operation: string
        //             };
        //         }}
        //     />
        default:
            return <Tooltip title={<span style={{ fontSize: '11px', fontWeight: '600' }}>{params.value}</span>}><span>{params.value}</span></Tooltip>;
    }
}


export const useCreateColumnsObject = (
    data_fields: FieldConfigInterface[],
    form: any,
    dynamicHeaders: boolean,
    showCalendarIfFirstPartNumber: boolean,
    data?: any,
    identityparams?: string,
    dynamicHeaderExceptions: string[] | undefined = undefined,
    minDateRestriction?: {
        property: string,
        query: string,
        depProp: string
    },
    maxDateRestriction?: string,
    checkboxSelectionVisibleOnly?: boolean,
) => {
    const { setColumns, records, currentServiceConfig } = useServiceRunsContext();
    let { serviceid } = useParams();

    // clear columns when switching between different table components

    useEffect(() => {

        if (serviceid !== currentServiceConfig.service_descriptive_name) {
            setColumns([])
        }

    }, [serviceid, currentServiceConfig.service_descriptive_name])

    useEffect(() => {
        if (serviceid === currentServiceConfig.service_descriptive_name) {
            if (dynamicHeaders) {
                if (records.length > 0) {
                    let localData;
                    if (dynamicHeaderExceptions && dynamicHeaderExceptions?.length > 0) {
                        localData = Object.keys(records[0]).filter(key => !dynamicHeaderExceptions.includes(key));
                    } else {
                        localData = Object.keys(records[0]);
                    }
                    setColumns(localData.map((key: string) => ({
                        field: key,
                        headerName: key,
                        sortable: true,
                        minWidth: 210,
                        flex: 1,
                        renderCell: (params: { value: string | number | string[] | number[], field: string }) => {
                            if (showCalendarIfFirstPartNumber) {
                                return !isNaN(+params.field.split(" ")[0]) ?
                                    <>
                                        <Field
                                            component={DGDatePicker}
                                            params={params}
                                            minDateRestriction={minDateRestriction ? minDateRestriction : undefined}
                                            maxDateRestriction={maxDateRestriction ? maxDateRestriction : undefined}
                                        />
                                    </> : params.value;
                            }
                            return params.value;
                        }

                    })))
                }

            } else {
                if (data_fields?.length > 0) {
                    setColumns(data_fields.filter(dl => dl.tableFieldType !== "none").map(dl => ({
                        field: dl.id,
                        headerName: dl.label,
                        filterable: !dl.disableFiltering,
                        sortable: dl.id === 'module_access' || dl.id === 'email_notification' ? false : true,
                        minWidth: dl.minWidth ?? 100,
                        maxWidth: dl.id == 'history' ? 70 : window.innerWidth,
                        disableExport: dl.disableExport,
                        flex: 1,
                        renderHeader: (params: GridColumnHeaderParams) => {
                            if (dl.id === 'module_access' || dl.id === 'email_notification') {
                                return (
                                    <>
                                        <RoleCheckbox
                                            form={form}
                                            params={null}
                                            columnParams={params}
                                            id={dl.id}
                                            identityparams={identityparams || ''}
                                            data={data}
                                            checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
                                        />
                                        <Tooltip title={<span style={{ fontSize: '9px' }}>{dl.label}</span>}><span style={{ marginLeft: '10px' }}>{dl.label}</span></Tooltip>
                                    </>
                                )
                            } else {
                                return <Tooltip title={<span style={{ fontSize: '9px' }}>{dl.label}</span>}><span>{dl.label}</span></Tooltip>;
                            }


                        },
                        headerClassName: 'MuiDataGrid-columnHeaderTitle',
                        renderCell: (params: { value: string | number | string[] | number[], row: { can_click: boolean; service_descriptive_name: string; } }) =>
                            // Array.isArray(params.value)
                            //     ? <div>
                            //         {params.value.map((item, index) => (
                            //             <React.Fragment key={index}>
                            //                 <span>{item}</span>
                            //                 <br />
                            //             </React.Fragment>
                            //         ))}
                            //     </div>
                            //     : 
                            renderCellMapper(dl, params, form, identityparams, data)
                    })))
                }
            }
        }
    }, [dynamicHeaderExceptions, data_fields, form.values.jsonID, records])
}

export const useGetColumnInitialState = (data_fields: FieldConfigInterface[]) => {

    const [initialState, setInitialState] = useState<{ columns: { columnVisibilityModel: { [key: string]: boolean } } }>({
        columns: {
            columnVisibilityModel: {},
        },
    });

    useEffect(() => {
        if (data_fields?.length > 0) {
            setInitialState(prev => {
                const current = { ...prev }
                data_fields.filter(dl => dl.tableFieldType !== "none").forEach(f => current.columns.columnVisibilityModel[f.id] = f.shown)
                return current
            })
        }

    }, [data_fields])

    return initialState;
}

export const useResetTableContentOnBUServiceChange = () => {
    const { bu } = useGlobalContext();
    const [lastBU, setlastBU] = useState<string>(bu);
    const {
        setRecords,
        setInitialRecords,
        currentServiceConfig,
        setGetTableData
    } = useServiceRunsContext();
    let { serviceid } = useParams();

    useEffect(() => {
        const isTable = currentServiceConfig.field_config.find(fc => fc.type === "TableField")
        if (bu !== "default" && bu !== lastBU && isTable) {
            setlastBU(bu);
            setRecords([]);
            setInitialRecords([]);
            // setGetTableData(false);
        }

        
    }, [bu, currentServiceConfig])

    
    useEffect(() => {
        const isTable = currentServiceConfig.field_config.find(fc => fc.type === "TableField")
        if (serviceid !== currentServiceConfig.service_descriptive_name && isTable) {
            setRecords([]);
            setInitialRecords([]);
            setGetTableData(false);
        }

    }, [serviceid, currentServiceConfig])
}