import { FC, useState, useEffect } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { YesNoModal } from "./YesNoModal"
import { serviceRunsDataInterface } from '../../../app/modules/ServiceRuns/interfaces/serviceRunsInterfaces';

export const useCheckTableContentChanged = (tableContent: boolean) => {
  const { records, tableErrors, initialRecords } = useServiceRunsContext();
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
      if (tableContent) {
          if (tableErrors.length === 0) {
              if (JSON.stringify([...records].sort((a, b) => +b.id - +a.id)) !== JSON.stringify([...initialRecords].sort((a, b) => +b.id - +a.id))) {
                  setCanSubmit(true);
              } else {
                  setCanSubmit(false);
              }
          } else {
            setCanSubmit(false);
          }
      }

  }, [tableContent, JSON.stringify(records), JSON.stringify(tableErrors)])

  return canSubmit;
}

export interface SubmitButtonInterface {
  form: {
    handleSubmit: () => {},
    values: { jsonID: { [key: string]: string } },
    dirty: boolean,
    setFieldValue: (a: string, b: string) => {}
  },
  state: serviceRunsDataInterface,
  label: string,
  isLoading: boolean,
  showInEdit: boolean,
  showInNew: boolean,
  action: string,
  color: string,
  modalConfirmation: boolean,
  tableContent: boolean,
  redirectsTo?: string
}


export const SubmitButton: FC<SubmitButtonInterface> = ({
  label,
  state,
  isLoading = false,
  form: { handleSubmit, dirty, values, setFieldValue },
  showInEdit,
  showInNew,
  action,
  color,
  modalConfirmation,
  tableContent,
  redirectsTo
}) => {

  const { editTable, newRow, tableContentReady, setTableQueryValuesObject } = useServiceRunsContext();
  const [open, setOpen] = useState(false);
  const dialoagAction = (opened: boolean, agree: boolean) => {
    setOpen(opened);
    if (agree) {
      // After submit we trigger get data process via setTableQueryValuesObject({})
      setTableQueryValuesObject({});
      handleSubmit();
    }
  };
  const canSubmit = useCheckTableContentChanged(tableContent);
  
  return (
    <>
      {((!state?.viewMode && !showInEdit && !showInNew && !editTable && !newRow) ||
        (showInEdit && editTable && tableContentReady) ||
        (showInNew && newRow && tableContentReady)) &&
        <>
          <button
            type='button'
            className={`btn btn-color-${color ? color : "success"} btn-${color ? color : "success"} text-white btn-sm me-5`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              if (action) setFieldValue('jsonID.action', action);
              if (redirectsTo) setFieldValue('redirectsTo', redirectsTo);
              if (modalConfirmation) {
                setOpen(true)
              } else {
                // After submit we trigger get data process via setTableQueryValuesObject({})
                setTableQueryValuesObject({});
                handleSubmit();   
              }
            }}
            disabled={
              isLoading || 
              (!dirty && !tableContent) || 
              (action && !(values.jsonID?.rowSelection?.length > 0) ? true : false) ||
              (tableContent && !canSubmit)}
          >
            <div className="d-flex align-items-center">
              <span>{label}</span>
              {isLoading && (
                <span className="spinner-border spinner-border-sm text-light ms-2"></span>
              )}
            </div>
          </button>
          <YesNoModal
            dialoagAction={dialoagAction}
            opened={open}
          />
        </>
      }
    </>
  );
}