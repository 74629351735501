import { FC } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";

export interface DeleteButtonInterface {
    params: {
        field: string,
        value: string | number;
        row: {[key:string]: number |boolean | string}
    },
}


export const DeleteButton: FC<DeleteButtonInterface> = ({
    params
}) => {

    const { setRecords } = useServiceRunsContext();
    const handleSubmit = () => {
        setRecords(prev => prev.filter(record => +record.id !== params.row.id))
    }

    return (
        <>
            {params.row.operation === "add" &&
                <IconButton
                    sx={{
                        color: "gray", // Default color
                        "&:hover": {
                            color: "red", // Change color on hover
                            transform: "scale(1.2)", // Optional: Slightly increase size on hover
                            transition: "0.3s ease-in-out",
                        },
                    }}
                    onClick={handleSubmit}
                >
                    <DeleteIcon />
                </IconButton>
            }
        </>



    );
}