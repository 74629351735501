import { FC, useEffect, useState, } from 'react'
import { Checkbox } from '../../../../../../_metronic/partials/forms/Checkbox'
import { cloneDeep } from "lodash";
import { useServiceRunsContext } from '../../../context/ServiceRuns-context';
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { IconButton } from '@mui/material';
import { useGlobalContext } from '../../../../../components/GlobalContext/GlobalContext';

export interface RoleCheckboxInterface {
    form: {
        touched: {},
        initialValues: { jsonID: { [key: string]: string } },
        errors: { jsonID: { [key: string]: string[] } },
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: {}) => {},
        values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
    },
    params: {
        value: string | number | string[] | number[],
        row: {[key:string]: number |boolean | string}
    } | null,
    columnParams: GridColumnHeaderParams | null,
    id: string,
    identityparams: string,
    data: any,
    checkboxSelectionVisibleOnly?: boolean
}

export const RoleCheckbox: FC<RoleCheckboxInterface> = ({
    form,
    params,
    columnParams,
    id,
    identityparams,
    data,
    checkboxSelectionVisibleOnly = true
}) => {

    const { records, setRecords, paginationModel } = useServiceRunsContext();
    const [recordsOnCurrentPage, setRecordsOnCurrentPage] = useState<{ [key: string]: string | number}[]>([])
    const [allSelected, setAllSelected] = useState(false);
    const [someSelected, setSomeSelected] = useState(false);
    const { bu } = useGlobalContext();

    useEffect(() => {

        if (data && "tabledata" in data && columnParams) {
            const recordsOnCurrentPageLocal = [] as { [key: string]: string | number }[];
            for (let i = paginationModel.page * paginationModel.pageSize; i < (paginationModel.page + 1) * paginationModel.pageSize; i++) {
                if (!records[i]) break;
                recordsOnCurrentPageLocal.push(records[i]);
            }
            if (checkboxSelectionVisibleOnly) {
                setAllSelected(recordsOnCurrentPageLocal.every(row => row[columnParams.field] === 'Y'));
                setSomeSelected(recordsOnCurrentPageLocal.some(row => row[columnParams.field] === 'Y') && recordsOnCurrentPageLocal.some(row => row[columnParams.field] === 'N'));
            } else {
                setAllSelected(records.every(row => row[columnParams.field] === 'Y'));
                setSomeSelected(records.some(row => row[columnParams.field] === 'Y') && records.some(row => row[columnParams.field] === 'N'));
            }
            setRecordsOnCurrentPage(recordsOnCurrentPageLocal);
        }
        
    }, [records, data, paginationModel])


    useEffect(() => {
        form.setFieldValue('jsonID.RoleTable', "")
        
    }, [bu])

    const checkAction = (identityObject: { [key: string]: string }, event: React.ChangeEvent<HTMLInputElement>, checkboxPropName: string) => {
        event.stopPropagation();
        if (Array.isArray(records)) {
            let localCopy = cloneDeep(records)

            if (params) {
                // find selected row from the table based on identityparams
                const selectedEntry = localCopy.filter((entry: { [key: string]: string | null | number }) =>
                    identityparams.split(",").every(param => entry[param] === identityObject[param])
                )
                if (selectedEntry && selectedEntry.length > 0) {
                    selectedEntry[0][checkboxPropName] = event.target.checked ? "Y" : 'N'
                }
            } else {
                if (allSelected) {
                    localCopy.forEach((row) => {
                        if (checkboxSelectionVisibleOnly) {
                            const matchingRow = recordsOnCurrentPage.find(record => identityparams.split(",").every(param => record[param] === row[param]));
                            if (matchingRow) row[checkboxPropName] = 'N';
                        } else {
                            row[checkboxPropName] = 'N'
                        }
                    });
                    // localCopy.forEach(row => row[checkboxPropName] = 'N');
                } else {
                    localCopy.forEach((row, index) => {
                        if (checkboxSelectionVisibleOnly) {
                            const matchingRow = recordsOnCurrentPage.find(record => identityparams.split(",").every(param => record[param] === row[param]));
                            if (matchingRow) row[checkboxPropName] = 'Y';
                        } else {
                            row[checkboxPropName] = 'Y'
                        }
                    });
                }
            }
            // if deselect module_access we deselect email_notification
            // if (checkboxPropName === "module_access" && !event.target.checked) {
            //   selectedEntry[0]['email_notification'] = 'N';
            // }
            // if select email_notification we select module_access
            // if (checkboxPropName === "email_notification" && event.target.checked) {
            //   selectedEntry[0]['module_access'] = "Y";
            // }
            setRecords(localCopy)
            // check for each row in the table data if it id different from initial data and push it in array
            let roleTableArray: Array<{ [key: string]: string | null | number; }> = [];
            localCopy.forEach((row, index) => {
                if (data && "tabledata" in data && JSON.stringify(row) !== JSON.stringify(data.tabledata[index])) {
                    let localRow = cloneDeep(row)
                    // create action property which determine if the operation is insert or update
                    if (data.tabledata[index]['user_exists'] === 'Y') localRow['action'] = 'update'
                    else localRow['action'] = 'insert'
                    roleTableArray.push(localRow)
                }
            })
        
            form.setFieldValue('jsonID.RoleTable', roleTableArray)
        }
    }

    return (
        <>
            {params
                ? <Checkbox
                    checked={params?.value === 'Y' ? true : false}
                    //checkbox prop name
                    checkboxPropName={id}
                    disabled={false}
                    // identityObject uniquely identified each row in the table like composite primary key
                    identityObject={identityparams.split(",").reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: params?.row[currentValue as keyof typeof params.row] }), {})}
                    checkAction={checkAction}
                />
                : <Checkbox
                    checked={allSelected}
                    //checkbox prop name
                    checkboxPropName={id}
                    disabled={false}
                    // identityObject uniquely identified each row in the table like composite primary key
                    identityObject={{ id }}
                    checkAction={checkAction}
                    indeterminate={someSelected}
                />
            }
        </>
    )
}