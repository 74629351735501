import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridPaginationModel,
  GridCallbackDetails,
  GridEventListener,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridFilterModel,
  GridRowSelectionModel,
  DataGridProProps,
  GridFilterPanel,
  useGridApiRef,
  GridGroupNode,
} from '@mui/x-data-grid-pro';
import { CustomFooter } from './Footer';
import { CustomNoRowsOverlay } from './CustomNoRowsOverlay';
import React, { FC, memo, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface TableComponentInterface {
  columns: GridColDef[],
  filteredData: GridRowsProp,
  loading: boolean,
  handleRowClick?: GridEventListener<'rowClick'>,
  sortModelData: GridSortModel,
  paginationModel: GridPaginationModel,
  onPaginationModelChange: (model: GridPaginationModel, details: GridCallbackDetails) => void,
  onSortModelDataChange: (model: GridSortModel, details: GridCallbackDetails) => void,
  newRecord?: () => void,
  newRecordLabel?: string,
  filterModel: GridFilterModel,
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>,
  initialState?: GridInitialStateCommunity,
  hideToolBar?: boolean,
  disableTableEdit?: boolean,
  checkboxSelection?: boolean,
  checkboxSelectionVisibleOnly?: boolean,
  setRowSelectionModel?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
  rowSelectionModel?: GridRowSelectionModel,
  disableColumnMenu?: boolean,
  disablePagination?: boolean,
  getTreeDataPath?: DataGridProProps['getTreeDataPath'],
  groupingColDef?: { headerName: string },
  getTableData?: boolean,
  setGetTableData?: React.Dispatch<React.SetStateAction<boolean>>,
  serverSideFiltering?: boolean,
  filterModelChanged?: boolean,
  setFilterModelChanged?: React.Dispatch<React.SetStateAction<boolean>>
  handleGroupExpansionChange?: Function,
  expandedGroups?: Set<string>
}

export const TableComponent: FC<TableComponentInterface> = memo(({
  columns,
  filteredData,
  loading,
  handleRowClick,
  sortModelData,
  paginationModel,
  onPaginationModelChange,
  onSortModelDataChange,
  newRecord,
  newRecordLabel,
  filterModel,
  setFilterModel,
  initialState = undefined,
  hideToolBar = false,
  disableTableEdit = false,
  checkboxSelection = false,
  checkboxSelectionVisibleOnly = true,
  setRowSelectionModel,
  rowSelectionModel,
  disableColumnMenu = false,
  disablePagination = false,
  getTreeDataPath,
  groupingColDef,
  getTableData,
  setGetTableData,
  serverSideFiltering,
  filterModelChanged,
  setFilterModelChanged,
  handleGroupExpansionChange,
  expandedGroups
}) => {
  const themeMode = localStorage.getItem("kt_theme_mode_value");
  const apiRef = useGridApiRef();

  React.useEffect(() => {
    apiRef.current.subscribeEvent('rowExpansionChange', (params) => {
      handleGroupExpansionChange && handleGroupExpansionChange(params.groupingKey, params.childrenExpanded);
    });
  }, [apiRef]);

  // const [filterModel, setFilterModel] = useState<GridFilterModel>({
  //   items: [], // Initialize with an empty array
  // });

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          // '& .MuiButtonBase-root': {
          //   background:"linear-gradient(to right, #f40009, #e5813e, #6ac9ce)",
          //   WebkitBackgroundClip: "text",
          //   WebkitTextFillColor: "transparent",
          //   color: "#f40009",
          //   fontWeight: "600",
          //   "&:hover": {
          //     background: "#f5f5f5",
          //     WebkitTextFillColor: "inherit",
          //     color: "#f40009"
          //   }
          // },
        }}
      >
        <GridToolbarColumnsButton
          slotProps={{
            button: {
              style: {
                color: "#f40009",
                fontWeight: "600",
              },
              startIcon: <ViewColumnIcon sx={{ color: "#f40009" }} />
            }
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#e5813e",
                fontWeight: "600",
              },
              startIcon: <FilterListIcon sx={{ color: "#e5813e" }} />
            }
          }}
        />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }}
          slotProps={{
            button: {
              style: {
                color: "#6ace7f",
                fontWeight: "600",
              },
              startIcon: <SaveAltIcon sx={{ color: "#6ace7f" }} />
            }
          }}
        />
        {newRecord && newRecordLabel &&
          <Button
            className="d-flex align-items-center table-button-hover"
            style={{
              color: '#6ac9ce',
            }}
            sx={{
              color: '#6ac9ce',
              fontSize: '0.8125rem',
              cursor: 'pointer',
              paddingLeft: '1px',
              paddingTop: '4px',
              paddingBottom: '4px',
              fontWeight: "600",
              borderRadius: "5px",
              '&:hover': {
                backgroundColor: "rgba(25, 118, 210, 0.04)"
              },
              '& .MuiButtonBase-root': {
                color: '#6ac9ce'
              },
            }}
            onClick={newRecord}
          >
            <AddIcon
              sx={{
                color: "#6ac9ce",
              }}
            />
            <div className='ms-1' style={{ paddingRight: "1px" }}>{`NEW ${newRecordLabel}`}</div>
          </Button>
        }
      </GridToolbarContainer>
    );
  }

  const MemoizedFilterPanel = useMemo(() => {
    return function FilterPanel() {
      return (
        <>
          <GridFilterPanel
            sx={{
              '& .MuiButtonBase-root': {
                color: '#e5813e !important',
                fontWeight: 600
              },
              '& .MuiFormLabel-root.Mui-focused': {
                color: '#e5813e !important'
              },
              '& .MuiInputBase-root.Mui-focused::after': {
                borderBottom: '2px solid #e5813e !important'
              }
            }}
          />
          <div style={{
            padding: '4px',
            position: 'absolute',
            bottom: 0,
            left: '125px'
          }}
          >
            <Button
              startIcon={<FilterAltIcon />}
              sx={{
                color: "#e5813e",
                fontWeight: 600
              }}
              onClick={() => apiRef.current.hideFilterPanel()}
            >
              APPLY
            </Button>
          </div>
        </>
      );
    };
  }, [apiRef]);

  return <>
    <style>{`.MuiDataGrid-root { border: none; }`}</style>
    <DataGridPro
      apiRef={apiRef}
      treeData={getTreeDataPath && true}
      pagination
      // getRowHeight={() => 'auto'}
      rowHeight={40}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      isGroupExpandedByDefault={(node: GridGroupNode) => {
        return expandedGroups?.has(node.groupingKey as string) || false
      }}
      sx={{
        // '& .MuiDataGrid-cell:hover': {
        //     color: '#3783E7',
        // },
        '& .MuiDataGrid-cell': {
          // paddingY: '7px',
          overflowWrap: 'anywhere',
          // whiteSpace: 'nowrap',
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiBox-root': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`,
        },
        '& .MuiCircularProgress-colorPrimary': {
          color: '#3783E7',
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          display: 'inline',
          textOverflow: 'ellipsis'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
          // color: "#b5b5c3",
          textTransform: 'uppercase',
          letterSpacing: '.1rem',
          // overflow: 'visible !important',
          fontSize: '.9rem',
          whiteSpace: "nowrap",
          lineHeight: "normal",
          color: themeMode === 'dark' ? 'white' : '#000000de'
        },
        '& .MuiDataGrid-row': {
          fontSize: '13px'
        },
        '& .MuiToolbar-root-MuiTablePagination-toolbar': {
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ccc',
          padding: '10px',
        },
        '& .MuiButtonBase-root': {
          color: themeMode === 'dark' ? 'white' : '#000000de'
        },
        '& .MuiTablePagination-selectLabel': {
          marginY: '0px'
        },
        '& .MuiTablePagination-input': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiTablePagination-selectIcon': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiTablePagination-displayedRows': {
          marginY: '0px',
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiPagination-ul': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`,
          '& .MuiButtonBase-root.Mui-selected': {
            backgroundColor: "#f40009"
          },
        },
        '& .MuiDataGrid-footerContainer': {
          paddingTop: '20px'
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none'
        },
        '.MuiTablePagination-root .MuiTablePagination-selectLabel': {
          display: 'none'
        },
        '& .MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected': {
          backgroundColor: 'red',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: "none"
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: "none"
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          height: "unset !important",
          borderBottom: "1px solid red !important",
          marginBottom: "10px",
        },
        "& .MuiDataGrid-row--borderBottom": {
          height: "100% !important",
          backgroundColor: 'transparent !important',
        },
        "& .MuiDataGrid-columnHeaders": {
          // Forced to use important since overriding inline styles
          maxHeight: "168px !important",
          height: "50px",
          justifyContent: "center",
          '& .MuiButtonBase-root': {
            color: "#f40009"
          },
          '& .MuiDataGrid-columnSeparator': {
            '&:hover': {
              color: "#6ac9ce"
            }
          }
        },
        "& .MuiDataGrid-overlayWrapper": {
          top: "0"
        },
        "& .MuiDataGrid-toolbarContainer": {
          marginBottom: "6px",
        },
        "& .MuiDataGrid-treeDataGroupingCellToggle": {
          "& .MuiIconButton-root": {
            fontSize: "20px",
            fontWeight: 300,
            color: "red",
          },
          marginRight: '5px'
        },
        "& .MuiDataGrid-treeDataGroupingCell span": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }}
      slots={{
        toolbar: hideToolBar ? null : CustomToolbar,
        footer: disablePagination ? undefined : CustomFooter,
        noRowsOverlay: () => <CustomNoRowsOverlay loading={loading} />,
        filterPanel: MemoizedFilterPanel
      }}
      // slotProps={{
      //   filterPanel: {
      //     sx: {
      //       '& .MuiButtonBase-root': {
      //         color: '#6ac9ce !important',
      //         fontWeight: 600
      //       },
      //       '& .MuiFormLabel-root.Mui-focused': {
      //         color: '#6ac9ce !important'
      //       },
      //       '& .MuiInputBase-root.Mui-focused::after': {
      //         borderBottom: '2px solid #6ac9ce !important'
      //       }
      //     }
      //   },
      //   columnsPanel: {
      //     sx: {
      //       '& .MuiOutlinedInput-root': {
      //         '&.Mui-focused fieldset': {
      //           borderColor: '#6ac9ce'
      //         }
      //       },
      //       '& .MuiButtonBase-root': {
      //         color: '#6ac9ce !important',
      //         fontWeight: 600
      //       },
      //     }
      //   }
      // }}
      slotProps={{
        filterPanel: {
          sx: {
            '& .MuiButtonBase-root': {
              color: '#e5813e !important',
              fontWeight: 600
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#e5813e !important'
            },
            '& .MuiInputBase-root.Mui-focused::after': {
              borderBottom: '2px solid #e5813e !important'
            }
          },
        },
        columnsPanel: {
          sx: {
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#f40009'
              }
            },
            '& .MuiButtonBase-root': {
              color: '#f40009 !important',
              fontWeight: 600
            },
          }
        }
      }}
      columns={columns}
      rows={loading ? [] : filteredData}
      // loading={loading}
      pageSizeOptions={[3, 5, 10, 30, 50, 100]}
      onRowClick={disableTableEdit ? undefined : handleRowClick}
      // auto-height for the table content
      getRowClassName={(params) => {
        let classString = '';
        params.row.can_click && !disableTableEdit ? classString += "can-hover-open" : classString += "cannot-hover-open";
        params.indexRelativeToCurrentPage % 2 === 0 ? classString += " row-even" : classString += " row-odd";
        return classString;
      }}
      autoHeight
      disableColumnMenu={disableColumnMenu}
      // disableColumnFilter
      disableDensitySelector
      checkboxSelection={checkboxSelection}
      checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
      disableRowSelectionOnClick
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sortModel={sortModelData}
      onSortModelChange={onSortModelDataChange}
      initialState={initialState}
      filterModel={filterModel}
      filterMode={serverSideFiltering ? 'server' : 'client'}
      onFilterModelChange={(model) => {
        if (serverSideFiltering) {
          setFilterModelChanged && setFilterModelChanged(true);
          setGetTableData && setGetTableData(false);
        }
        setFilterModel(model);
      }}
      onStateChange={(state) => {
        if (serverSideFiltering && filterModelChanged && !state.preferencePanel.open) {
          setGetTableData && setGetTableData(true);
          setFilterModelChanged && setFilterModelChanged(false);
        }
      }}
      onRowSelectionModelChange={setRowSelectionModel ? (newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      } : undefined}
      rowSelectionModel={rowSelectionModel ? rowSelectionModel : undefined}
      hideFooterPagination={disablePagination}
    />
  </>
})






